$box-width: 9.5rem;
$box-height: 3.5rem;

.arrow-button-left {
  height: $box-height;
  width: $box-width;
  color: #ffc107;
  z-index: 999;
  transform: scale(3);
  margin-left: 1.5rem;
}

.arrow-button-right {
  height: $box-height;
  width: $box-width;
  color: #ffc107;
  z-index: 999;
  transform: scale(3);
}

.control-container {
  display: flex;
  max-width: 100%;
  height: 88vh;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
}

.image:hover > .overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 100;
  display: block;
}

button:active {
  outline: none;
  border: none;
}

button:focus {
  outline: 0;
}

.carousel-control-next {
  filter: invert(100%);
}

.carousel-control-prev {
  filter: invert(100%);
}
