.tipmodal {
    >div {
        margin: 0 0.2rem;
    }
    background-color: #40424e;
    color: white;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.svg-pic {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}

.save-me-svg {
  width: 200px;
  display: block;
  margin: 40px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash 1.9s ease-in-out;
    animation: dash 1.9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 1.9s 1.35s ease-in-out forwards;
    animation: dash 1.9s 1.35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 1.9s 1.35s ease-in-out forwards;
    animation: dash-check 1.9s 1.35s ease-in-out forwards;
  }
}

p {
  text-align: center;
  margin: 20px 0 35px;
  font-size: 1.25em;
  &.success {
    color: #26902A;
    font-size: 2.3em;
  }
  &.error {
    color: #D06079;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

.modal-payment {
    // margin-top: 1%;
    color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] { // this is to disable the arrows for HTML5 input type=number element
    -moz-appearance:textfield; /* Firefox */
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip img {
  visibility: visible;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 45%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


@media screen and (max-width: 415px) {
      .bar {
        margin-top: .5em !important;
        width: 75% !important;
      }
      .between {
        margin-top: 6px !important;
    }
}

