
.modal-container {
    height: fit-content !important;
}

.modal-title {
    font-size: 1.6rem;
    padding-bottom: .5rem;
    font-weight: bold;
    color: white;
    user-select: none;
}

.modal-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
}

.modal-button {
    height: 3rem;
    min-width: 6rem;
}

.media {
    width: 8rem;
    height: 4rem;
    border: none;
    display: flex;

    &:hover {
        background-color: white;
        border: 2px solid black;
    }

    &.confirm {
        background-color: green;
    }
    &.cancel {
        background-color: red;
    }
}

.thumb {
    width: 2rem;
    height: 2rem;
    align-items: center;
    margin-left: 35%;
  }

.big {
    font-size: 1.5rem;
}

.sub-header {
    margin-top: 1.5rem;
    font-size: 1.1rem;
}

.modal-table {
    width: 70%;
    max-height: 25%;
    margin: .5em auto .5em auto;
}

.modal {
    color: white;
    // max-width: 35%;
    z-index: 2;
    width: 43%;
    max-height:1005px;
    overflow-y: hidden;
    text-align: center;
    justify-content: center;
    margin: 10rem auto 0 auto;
    padding: 2em 1.5em;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    //border-radius: 3px;

    &:focus {
        outline: none;
    }
    &.wide {
        width: 50%;
        margin-top: 0;
    }
}

:global {
    .ReactModal__Content, .ReactModal__Content--after-open {
        background-color: rgba(255, 255, 255, 1);
    }
    .ReactModal__Overlay, .ReactModal__Overlay--after-open {
        z-index: 1300;
        background-color: rgba(0, 0, 0, .5) !important;
    }
}

.info-container {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.info-column {
    flex: 1;
    > div {
        min-width: fit-content;
        height: 1rem;
    }
}

.close {
    width: 1rem;
    margin: -1.3rem .5rem -1.3rem auto !important;
    > img {
        border-radius: 10px;
        padding: .3rem .5rem;

        &:hover {
            background-color: rgba(200, 200, 200, .3);
        }
    }
}

.left-icon {
  margin-right:10px;
}

.inline-text {
  display: inline !important;
}

.condensed-table {
  padding-top: 0 !important;
  padding-bottom: 10px !important;
}

.buffer-top {
  margin-top: 30px;
}

@media screen and (max-width: 608px){
  .modal {
    width: 50%;
  }
}

@media screen and (max-height: 823px) {
    .modal {
        width: 80%;
        height: 50% !important;
      }
}

@media screen and (max-height: 812px) {
    .modal {
        width: 80%;
        height: 55% !important;
      }
}

@media screen and (max-height: 736px) {
    .modal {
        width: 80%;
        height: 57% !important;
      }
}

@media screen and (max-height: 731px) and (max-width: 411px) {
    .modal {
        width: 80%;
        height: 57% !important;
      }
}

@media screen and (max-height: 667px) {
    .modal {
        width: 80%;
        height: 70% !important;
      }
}

@media screen and (max-height: 640px) {
    .modal {
        width: 80%;
        height: 70% !important;
      }
}