$break-small: 414px;

body {
    background-color: #40424e;
}

.container{
    display: inline-block;
    position: fixed;
    top: 50%;
    text-align: center;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: $break-small) {
        width: 95%;
      }
}

.content {
    margin: 8;

}
