.vertical-timeline--two-columns .vertical-timeline-element-icon {
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    box-shadow: none !important;
    background: #4c3d9e;
}

.timeline-image {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    height: auto;
}

.timeline-icon {
    @media only screen and (min-width: 1170px) {
        width: 52px;
        height: 52px;

    }

    @media only screen and (max-width: 1169px) {
        width: 34px;
        height: 34px;

    }

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.gmo-label {
    >img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }
}

.gmo-label {
    color: #5ecb55;
    font-weight: bold;
    font-size: 12px;
    align-content: center;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
}

.vertical-timeline {
    &::before {
        background: #4c3d9e;
    }
}

.header-gmo-container {
    // display: flex;
    // flex-wrap: nowrap;
    // justify-content: space-between;
}